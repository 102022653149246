import React from "react";
import classNames from "classnames";

function Tooltip(props) {
    let { style, className, type } = props;
    const classes = classNames(className, type ? "trpr__tooltip-" + type : "trpr__tooltip-primary");
    return (
        <div className={classes} style={style}>
            <span>{props.children}</span>
        </div>
    );
}

export default Tooltip;
