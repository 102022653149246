/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import { useCallback, Fragment, useMemo, useState, useEffect, createContext, useContext } from "react";
import { useLockBodyScroll } from "react-use";
import debounce from "lodash.debounce";
import throttle from "lodash.throttle";
import { AnimateSharedLayout, AnimatePresence, motion } from "framer-motion";
import {
    isMacLike,
    mobileMediaQuery,
    tabletMediaQuery,
    desktopMediaQuery,
    extraSmallMediaQuery,
    slideUpVariant,
    fadeSlideUpVariant,
    fadeVariants,
    motionTransition,
} from "./constants";
import random from "lodash.random";

const getIsMobile = () => window.matchMedia(mobileMediaQuery).matches;
const getIsTablet = () => window.matchMedia(tabletMediaQuery).matches;
export const getIsDesktop = () => window.matchMedia(desktopMediaQuery).matches;
export const getIsExtraSmall = () => window.matchMedia(extraSmallMediaQuery).matches;

function useBreakpoints() {
    const [isMobile, setIsMobile] = useState(getIsMobile());
    const [isTablet, setIsTablet] = useState(getIsTablet());
    const [isDesktop, setIsDesktop] = useState(getIsDesktop());
    const [isExtraSmall, setIsExtraSmall] = useState(getIsExtraSmall());

    useEffect(() => {
        function handleResize() {
            setIsMobile(getIsMobile());
            setIsTablet(getIsTablet());
            setIsDesktop(getIsDesktop());
            setIsExtraSmall(getIsExtraSmall());
        }
        const debouncedHandleResize = debounce(handleResize, 300);
        window.addEventListener("resize", debouncedHandleResize);
        return () => {
            debouncedHandleResize.flush();
            window.removeEventListener("resize", debouncedHandleResize);
        };
    }, []);

    return {
        isMobile,
        isTablet,
        isDesktop,
        isExtraSmall,
    };
}

// callback arg must use useCallback hook
export function useScroll(callback, timeout = 300) {
    useEffect(() => {
        const throttledHandleScroll = throttle(callback, timeout);
        window.addEventListener("scroll", throttledHandleScroll);
        return () => {
            throttledHandleScroll.flush();
            window.removeEventListener("scroll", throttledHandleScroll);
        };
    }, [callback, timeout]);
}

// callback arg must use useCallback hook
export function useResize(callback, timeout = 300) {
    useEffect(() => {
        const throttledHandle = throttle(callback, timeout);
        window.addEventListener("resize", throttledHandle);
        throttledHandle();
        return () => {
            throttledHandle.flush();
            window.removeEventListener("resize", throttledHandle);
        };
    }, [callback, timeout]);
}

const BreakpointsContext = createContext();

export function BreakpointsProvider(props) {
    const breakpoints = useBreakpoints();
    return <BreakpointsContext.Provider value={breakpoints}>{props.children}</BreakpointsContext.Provider>;
}

export const useBreakpointsContext = () => useContext(BreakpointsContext);

const modalBackdropCSS = css`
    background: rgba(51, 51, 51, 0.5);
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 6;
`;
const modalCSS = css`
    position: fixed;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 6;
    overflow: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    pointer-events: none;

    &::before,
    &::after {
        content: "";
        flex: 1 1 0;
        width: 0;
    }

    > * {
        flex: none;
        pointer-events: all;
    }
`;
const ModalWithBackdrop = props => {
    const { children, showModal, closeModal, motionProps } = props;

    return useMemo(() => {
        const motionPropsCSS = motionProps?.css;
        return (
            <Fragment>
                <AnimatePresence>
                    {showModal && (
                        <motion.div
                            animate="visible"
                            css={modalBackdropCSS}
                            exit="hidden"
                            initial="hidden"
                            transition={motionTransition}
                            variants={fadeVariants}
                            onClick={closeModal}
                        ></motion.div>
                    )}
                </AnimatePresence>
                <AnimatePresence>
                    {showModal && (
                        <motion.div
                            animate="visible"
                            exit="hidden"
                            initial="hidden"
                            transition={motionTransition}
                            variants={fadeVariants}
                            {...motionProps}
                            css={[modalCSS, motionPropsCSS]}
                        >
                            {children}
                        </motion.div>
                    )}
                </AnimatePresence>
            </Fragment>
        );
    }, [children, showModal, closeModal, motionProps]);
};
export const useModal = (options = {}) => {
    const { motionProps } = options;
    const [showModal, setShowModal] = useState(false);
    const lockBodyScroll = !isMacLike && showModal;
    const closeModal = useCallback(() => setShowModal(false), []);
    const modalProps = useMemo(() => {
        return { motionProps, showModal, closeModal };
    }, [motionProps, showModal, closeModal]);

    useLockBodyScroll(lockBodyScroll);

    return [showModal, setShowModal, ModalWithBackdrop, modalProps];
};

export const useReservationModalOptions = ({ isGreaterThan530, childrenRef }) => {
    const reservationModalOptions = useMemo(
        () => ({
            motionProps: {
                key: isGreaterThan530 ? "desktop" : "mobile",
                variants: isGreaterThan530 ? fadeVariants : slideUpVariant,
            },
            childrenRef,
        }),
        [isGreaterThan530, childrenRef],
    );
    return reservationModalOptions;
};

const toastsCSS = css`
    position: fixed;
    top: 0;
    right: 0;
`;
const Toasts = props => {
    const { renderToast, toasts, className, motionProps } = props;
    return useMemo(() => {
        return (
            <AnimateSharedLayout>
                <motion.div
                    layout
                    transition={motionTransition}
                    css={!className ? toastsCSS : undefined}
                    className={className}
                >
                    <AnimatePresence>
                        {toasts.map(toast => (
                            <motion.div
                                layout
                                key={toast.id}
                                animate="visible"
                                exit="hidden"
                                initial="hidden"
                                transition={motionTransition}
                                variants={fadeSlideUpVariant}
                                {...motionProps}
                            >
                                {renderToast(toast.props)}
                            </motion.div>
                        ))}
                    </AnimatePresence>
                </motion.div>
            </AnimateSharedLayout>
        );
    }, [toasts, className, renderToast, motionProps]);
};
export const useToast = options => {
    const { defaultTimeout, renderToast, motionProps } = options;
    const [toasts, setToasts] = useState([]);

    const showToast = useCallback(
        async ({ timeout = defaultTimeout, toastProps } = {}) => {
            let timeoutId;
            const toastId = random(0, 9999);
            const hideToast = () => {
                setToasts(toasts => toasts.filter(toast => toast.id !== toastId));
                clearTimeout(timeoutId);
            };
            //Show toast
            setToasts(toasts => [
                ...toasts,
                {
                    props: {
                        ...toastProps,
                        hideToast,
                    },
                    id: toastId,
                },
            ]);
            //Hide toast after timeout
            await new Promise(resolve => {
                timeoutId = setTimeout(() => {
                    if (timeout !== Infinity) {
                        hideToast();
                    }
                    resolve();
                }, timeout);
            });
        },
        [defaultTimeout],
    );
    const toastsProps = useMemo(
        () => ({
            renderToast,
            toasts,
            motionProps,
        }),
        [renderToast, toasts, motionProps],
    );

    return [showToast, Toasts, toastsProps];
};
