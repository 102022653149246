import React, { useMemo, createContext, useReducer, useContext } from "react";

export const getDefaultIsAutoFollowActive = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const isAutoFollowActive = urlParams.get("isAutoFollowActive")
        ? urlParams.get("isAutoFollowActive") === "true"
        : true;
    return isAutoFollowActive;
};
export const getDefaultIsAutoFollowActiveAtlas = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const isAutoFollowActive = urlParams.get("isAutoFollowActive")
        ? urlParams.get("isAutoFollowActive") === "true"
        : false;
    return isAutoFollowActive;
};
const initialState = {
    currentPlaceName: null,
    progressByDay: {},
    windowHeight: 0,
    placeEndNodeByPlaceId: {},
};

/**
 * Main reducer method
 * @param state
 * @param action
 * @returns {{routes: null, loopDirection: *, route: null, selectedFilters: [], itineraryId: number, start: [], filters: [], loading: boolean}|{routes: null, loopDirection: string, route: *, selectedFilters: [], itineraryId: number, start: [], filters: [], loading: boolean}|{routes: *, loopDirection: string, route: null, selectedFilters: [], itineraryId: number, start: [], filters: [], loading: boolean}|{routes: null, loopDirection: string, route: null, selectedFilters: [], itineraryId: number, start: *, filters: [], loading: boolean}|{routes: null, loopDirection: string, route: null, selectedFilters: *[], itineraryId: number, start: [], filters: [], loading: boolean}|{routes: null, loopDirection: string, route: null, selectedFilters: [], itineraryId: number, start: [], filters: [], loading: boolean}|{routes: null, loopDirection: string, route: null, selectedFilters: [], itineraryId: number, start: [], filters: [], loading: *}|{routes: null, loopDirection: string, route: null, selectedFilters: [], itineraryId: *, start: [], filters: [], loading: boolean}|{routes: null, loopDirection: string, route: null, selectedFilters: [], itineraryId: number, start: [], filters: *, loading: boolean}}
 */
function reducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case "SET_CURRENT_PLACE_NAME":
            return { ...state, currentPlaceName: payload };
        case "SET_PROGRESS_BY_DAY":
            return { ...state, progressByDay: payload };
        case "SET_WINDOW_HEIGHT":
            return { ...state, windowHeight: payload };
        case "SET_PLACE_END_NODE_BY_PLACE_ID":
            return { ...state, placeEndNodeByPlaceId: payload };
        case "ADD_TO_PLACE_END_NODE_BY_PLACE_ID":
            return {
                ...state,
                placeEndNodeByPlaceId: {
                    ...state.placeEndNodeByPlaceId,
                    ...payload,
                },
            };
        default:
            return state;
    }
}

/**
 * Sets Store Context
 * @type {React.Context<unknown>}
 */
export const Store = createContext();

/**
 * Initializes Store provider
 * @param props
 * @return {JSX.Element}
 * @constructor
 */
export function StoreProvider(props) {
    const [state, dispatch] = useReducer(reducer, initialState);
    const value = { state, dispatch };
    return <Store.Provider value={value}>{props.children}</Store.Provider>;
}

export const useStore = () => useContext(Store);

//Like connect of react-redux
export const connect = mapStateToProps => {
    return function withStore(WrappedComponent) {
        return function WithStore(passThroughProps) {
            const { state, dispatch } = useStore();
            const stateProps = mapStateToProps(state);
            return useMemo(
                () => {
                    return <WrappedComponent dispatch={dispatch} {...passThroughProps} {...stateProps} />;
                },
                // eslint-disable-next-line
        [
                    dispatch,
                    passThroughProps,
                    // eslint-disable-next-line
          ...Object.values(stateProps),
                ],
            );
        };
    };
};
