const urlParams = new URLSearchParams(window.location.search);
const showHeader = (urlParams.get("showHeader") || "").toLowerCase() === "true";

export const inPdfMode = [
    (urlParams.get("PDF") || "").toLowerCase(),
    (urlParams.get("pdf") || "").toLowerCase(),
].includes("true");

const API_DOMAIN = urlParams.get("domain") || process.env.REACT_APP_API_DOMAIN || "dev.tripper.app";
const domain = API_DOMAIN?.includes("https://") ? API_DOMAIN : `https://${API_DOMAIN}`;
export const termsLink = `${domain}/terms-and-conditions`;

export const mobileMediaQuery = "(max-width: 767px)";
export const tabletMediaQuery = "(min-width: 768px) and (max-width: 1111px)";
export const desktopMediaQuery = "(min-width: 1112px)";
export const extraSmallMediaQuery = "(max-width: 768px)";
export const ITINERARY_HEADER_HEIGHT = 80;
export const ITINERARY_HEADER_HEIGHT_MOBILE = 56;
export const DAY_GROUP_HEADER_HEIGHT = 64;
export const DAY_GROUP_HEADER_HEIGHT_MOBILE = 52;
export const MAP_HEIGHT_MOBILE = 225;
export const ATLAS_MAP_HEIGHT_MOBILE = 224;
export const MAP_HEADER_HEIGHT = 80;
export const ATLAS_MAP_HEADER_HEIGHT = 64;
export const MAP_HEADER_HEIGHT_MOBILE = 41;
export const MAP_PADDING = 75;
export const ROUTE_EXPLORER_MAP_HEIGHT_MOBILE = 200;
// export const ROUTE_EXPLORER_MAP_HEIGHT_TABLET = window.innerHeight / 3;
export const getRouteExplorerMapHeightTablet = (windowHeight = window.innerHeight) => windowHeight / 3;
export const APP_HEADER = showHeader ? 60 : 0;
export const APP_HEADER_MOBILE = showHeader ? 48 : 0;
export const PROXY = window.location.hostname === "localhost" ? "https://cors-anywhere.herokuapp.com" : "/cors-proxy";
export const isMacLike = navigator.platform.match(/(Mac|iPhone|iPod|iPad)/i) ? true : false;

export const slideUpVariant = {
    visible: {
        translateY: 0,
    },
    hidden: {
        translateY: "100%",
    },
};

export const fadeSlideUpVariant = {
    visible: {
        y: 0,
        opacity: 1,
    },
    hidden: {
        y: 72,
        opacity: 0,
    },
};

export const fadeVariants = {
    visible: {
        opacity: 1,
    },
    hidden: {
        opacity: 0,
    },
};

export const motionTransition = {
    duration: 0.2,
    ease: "easeOut",
};
