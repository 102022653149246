import React, { forwardRef } from "react";
import classNames from "classnames";
import Tooltip from "../tooltip/Tooltip";

const Button = forwardRef(function Button(props, ref) {
    let {
        tooltip,
        tooltipClasses,
        isActive,
        mobileSize,
        tabletSize,
        desktopSize,
        smSize,
        mdSize,
        target,
        rel,
        href,
        tag: BaseTag,
        children,
        text,
        disabled,
        icon,
        style,
        className,
        type,
        size,
        expand,
        onClick,
        htmlType,
        onClickButton,
        to,
    } = props;
    const classes = classNames(
        className,
        {
            "is-active": isActive,
            "expand": expand,
            "trpr__tooltip-primary-wrapper": tooltip,
        },
        type ? "trpr__n-btn-" + type : "trpr__n-btn-primary",
        size ? "trpr__btn-" + size : !smSize && !mdSize ? "trpr__btn-md" : "",
        smSize ? `trpr__btn-${smSize}@sm` : "",
        mdSize ? `trpr__btn-${mdSize}@md` : "",
        mobileSize ? `trpr__btn-${mobileSize}@mobile` : "",
        tabletSize ? `trpr__btn-${tabletSize}@tablet` : "",
        desktopSize ? `trpr__btn-${desktopSize}@desktop` : "",
    );
    const iconClass = "trpr__icon-" + icon;
    const showIconOnly = type === "icon";
    let iconContent;
    if (icon) {
        if (icon === "minus-bold") {
            iconContent = (
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2 9H16" stroke="#505050" strokeWidth="2" strokeLinecap="round" />
                </svg>
            );
        } else if (icon === "plus-bold") {
            iconContent = (
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2 9H16M9 16V2" stroke="#505050" strokeWidth="2" strokeLinecap="round" />
                </svg>
            );
        } else if (icon === "re-center-bold") {
            iconContent = (
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M1 2C1 1.44772 1.44772 1 2 1H6C6.55228 1 7 1.44772 7 2C7 2.55228 6.55228 3 6 3H3V6C3 6.55228 2.55228 7 2 7C1.44772 7 1 6.55228 1 6V2ZM11 2C11 1.44772 11.4477 1 12 1H16C16.5523 1 17 1.44772 17 2V6C17 6.55228 16.5523 7 16 7C15.4477 7 15 6.55228 15 6V3H12C11.4477 3 11 2.55228 11 2ZM2 11C2.55228 11 3 11.4477 3 12V15H6C6.55228 15 7 15.4477 7 16C7 16.5523 6.55228 17 6 17H2C1.44772 17 1 16.5523 1 16V12C1 11.4477 1.44772 11 2 11ZM16 11C16.5523 11 17 11.4477 17 12V16C17 16.5523 16.5523 17 16 17H12C11.4477 17 11 16.5523 11 16C11 15.4477 11.4477 15 12 15H15V12C15 11.4477 15.4477 11 16 11Z"
                        fill="#505050"
                    />
                    <path
                        d="M12 9C12 10.6569 10.6569 12 9 12C7.34315 12 6 10.6569 6 9C6 7.34315 7.34315 6 9 6C10.6569 6 12 7.34315 12 9Z"
                        fill="#505050"
                    />
                </svg>
            );
        } else {
            iconContent = <i className={iconClass}></i>;
        }
    }
    return (
        <BaseTag
            type={htmlType}
            ref={ref}
            to={to}
            target={target}
            rel={rel}
            href={href}
            className={classes}
            disabled={disabled}
            style={style}
            onClick={!disabled ? onClick || onClickButton : () => {}}
        >
            {tooltip && <Tooltip className={`is-floating trpr__mobile-alt ${tooltipClasses}`}>{tooltip}</Tooltip>}
            {iconContent} {!showIconOnly && <span>{text}</span>}
            {children}
        </BaseTag>
    );
});

Button.propTypes = {
    // img: PropTypes.string.isRequired,
    // title: PropTypes.string.isRequired,
    // itineraryName: PropTypes.string.isRequired,
    // info: PropTypes.shape({
    //     days: PropTypes.number.isRequired,
    //     stops: PropTypes.number.isRequired,
    //     distance:  PropTypes.number.isRequired,
    // }).isRequired,
    // doClickEmail: PropTypes.func.isRequired,
    // doClickDownloadGPX: PropTypes.func.isRequired,
    // doClickDownloadPDF: PropTypes.func.isRequired,
};

Button.defaultProps = {
    htmlType: "button",
    tag: "button",
    onClickButton: () => {},
};

export default Button;
