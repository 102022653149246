import React, { useContext } from "react";
import resources from "./languages";
import isObject from "lodash.isobject";
import get from "lodash.get";

export default function translate(lang) {
    const translation = resources[lang];

    return function(str, keyPath) {
        if (isObject(str)) {
            const result = get(str[lang], keyPath);
            if (!result) {
                return get(str["en"], keyPath);
            }
            return result;
        }

        if (!translation) return str;

        return translation[str] || str;
    };
}

export const TranslatorContext = React.createContext(() => "");
TranslatorContext.displayName = "Translator";

export const useTranslate = () => useContext(TranslatorContext);

export function withTranslate(WrappedComponent) {
    return function WithObserver(passThroughProps) {
        const translate = useTranslate();
        return <WrappedComponent translate={translate} {...passThroughProps} />;
    };
}
