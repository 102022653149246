import React from "react";
import Button from "../button/Button";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useParams } from "react-router-dom";

function AppHeader() {
    const { team } = useParams();
    return (
        <div className="trpr__app-header-container">
            <div
                className="trpr__app-header"
                css={css`
                    justify-content: space-between !important;
                `}
            >
                <div className="is-invisible-mobile">
                    {team === "northeastern-ontario" && (
                        <a href={"https://northernontario.travel/northeastern-ontario"}>
                            <Button>⬅ Northeastern Ontario</Button>
                        </a>
                    )}
                    {team === "algoma-country" && (
                        <a href={"https://northernontario.travel/algoma-country"}>
                            <Button>⬅ Algoma Country</Button>
                        </a>
                    )}
                    {team === "superior-country" && (
                        <a href={"https://northernontario.travel/superior-country"}>
                            <Button>⬅ Superior Country</Button>
                        </a>
                    )}
                    {team === "sunset-country" && (
                        <a href={"https://northernontario.travel/sunset-country"}>
                            <Button>⬅ Sunset Country</Button>
                        </a>
                    )}
                </div>
                <div
                    css={css`
                        position: absolute;
                        left: 50%;
                        transform: translateX(-50%);
                        padding: 20px;
                        text-align: center;
                        font-family: new-spirit-condensed, Georgia, "Times New Roman", times, serif;
                        color: white !important;
                        white-space: nowrap;
                        user-select: none;
                        -webkit-user-select: none;
                    `}
                >
                    Northern Ontario Travel • The Official Magazine
                </div>
            </div>
        </div>
    );
}

export default AppHeader;
